import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";

import ImgDeclarative from "../../assets/images/blog/declarative-design-system.png";
import { graphql, Link } from "gatsby";
import FacebookIcon from "../../assets/images/footer/facebook.svg";
import LinkedinIcon from "../../assets/images/footer/linkedin.svg";
import ShareIcon from "../../assets/images/blog/share.svg";
import ImgHeader from "../../assets/images/blog/pm1.png";
import Img2 from "../../assets/images/blog/pm2.png";
import Img3 from "../../assets/images/blog/pm3.png";
import ImgAuthor from "../../assets/images/blog/milica_m.jpeg";

const ProjectDelays = ({ item }) => {
  const { t } = useTranslation();
  const blogContent = [
    {
      date: `${t("blog.delays.date")}`,
      img: ImgHeader,
      img2: Img2,
      img3: Img3,
      authorImg: ImgAuthor,
      title: `${t("blog.delays.title")}`,
      text: `${t("blog.delays.description")}`,
      author: "Milica Milojkov",
      linkedinUrl: "https://rs.linkedin.com/in/milica-milojkov",
      role: "Project manager",
    },
  ];
  let windowHref = "";
  if (typeof window !== "undefined") {
    windowHref = window.location.href;
    // console.log("windowhref", windowHref);
  }
  const titleforUrl = "The major causes of project delays";
  const socials = [
    {
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${windowHref}&title=${titleforUrl}`,
      icon: <LinkedinIcon />,
      label: "Lindkedin share button",
    },
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${windowHref}`,
      icon: <FacebookIcon />,
      label: "Facebook share button",
    },
  ];
  const share = [
    {
      url: "https://www.facebook.com/ewa.soft/",
      icon: <ShareIcon />,
      label: "Copy link to share",
    },
  ];

  const toggleClass = () => {
    setActive(!isActive);
  };

  const CopyToClipboard = (toCopy) => {
    const el = document.createElement(`textarea`);
    el.value = toCopy;
    el.setAttribute(`readonly`, ``);
    el.style.position = `absolute`;
    el.style.left = `-9999px`;
    document.body.appendChild(el);
    el.select();
    document.execCommand(`copy`);
    document.body.removeChild(el);
  };
  const [isActive, setActive] = useState(false);
  return (
    <Layout>
      <Seo title="The major causes of project delays" />
      {blogContent.map((item, i) => (
        <div item={item} key={i}>
          <section className="m-blogpost">
            <div className="_wr">
              <div className="_w">
                <div className="-links">
                  <Link to="/">Home</Link> <Link to="/blog">Blog</Link>
                </div>
                <div
                  className="m-blogpost__image"
                  style={{ backgroundImage: `url("${item.img}")` }}
                ></div>
                <div className="_wr">
                  <div className="_w m-blogpost__wrap">
                    <div className="m-blogpost__wrap--about ofs_m2 _m8">
                      <div className="-date">
                        <b>Date: </b> <span> {item.date} </span>
                      </div>
                      <div className="-author">
                        <div>
                          <b>Author: </b>
                        </div>
                        <div
                          className="-image"
                          style={{
                            backgroundImage: `url("${item.authorImg}")`,
                          }}
                        ></div>
                        <div>
                          <a
                            href={item.linkedinUrl}
                            className="-name"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {item.author}
                          </a>
                          <p className="-role">{item.role}</p>
                        </div>
                      </div>
                    </div>
                    <div className="m-blogpost__wrap--title ofs_m2 _m8">
                      <h1>{item.title}</h1>
                      <span className="a-line -red"></span>
                    </div>
                    <div className="m-blogpost__wrap--post ofs_m2 _m8">
                      <p>{t("blog.delays.post.paragraph1")}</p>
                      <ul className="-margin">
                        <li> {t("blog.delays.post.listContent.list1")}</li>
                        <li> {t("blog.delays.post.listContent.list2")}</li>
                        <li> {t("blog.delays.post.listContent.list3")}</li>
                        <li> {t("blog.delays.post.listContent.list4")}</li>
                        <li> {t("blog.delays.post.listContent.list5")}</li>
                        <li> {t("blog.delays.post.listContent.list6")}</li>
                        <li> {t("blog.delays.post.listContent.list7")}</li>
                      </ul>
                      <div
                        className="-image"
                        style={{ backgroundImage: `url("${item.img2}")` }}
                      ></div>
                      <p className="-margin">
                        {t("blog.delays.post.paragraph2")}
                      </p>
                      <p className="-margin">
                        {t("blog.delays.post.paragraph3")}
                      </p>
                      <p className="-margin">
                        <b>{t("blog.delays.post.paragraph4")}</b>
                      </p>
                      <p>{t("blog.delays.post.paragraph5")}</p>
                      <p className="-margin">
                        <b>{t("blog.delays.post.paragraph6")}</b>
                      </p>
                      <p>{t("blog.delays.post.paragraph7")}</p>
                      <p className="-margin">
                        <b>{t("blog.delays.post.paragraph8")}</b>
                      </p>
                      <p>{t("blog.delays.post.paragraph9")}</p>
                      <p className="-margin">
                        <b>{t("blog.delays.post.paragraph10")}</b>
                      </p>
                      <p>{t("blog.delays.post.paragraph11")}</p>
                      <p className="-margin">
                        <b>{t("blog.delays.post.paragraph12")}</b>
                      </p>
                      <p>{t("blog.delays.post.paragraph13")}</p>
                      <div
                        className="-image"
                        style={{
                          backgroundImage: `url("${item.img3}")`,
                          backgroundSize: "contain",
                        }}
                      ></div>
                      <h2 className="-margin">
                        {t("blog.delays.post.paragraph14")}
                      </h2>
                      <p className="-margin">
                        {t("blog.delays.post.paragraph15")}
                      </p>
                      <ul className="-margin">
                        <li> {t("blog.delays.post.listContent.list8")}</li>
                        <li> {t("blog.delays.post.listContent.list9")}</li>
                        <li> {t("blog.delays.post.listContent.list10")}</li>
                        <li> {t("blog.delays.post.listContent.list11")}</li>
                      </ul>
                      {/* <div className="-quote">
                        <p>{t("blog.delays.post.quote")}</p>
                      </div> */}
                    </div>
                    <div className="m-blogpost__wrap--share ofs_m2 _m8">
                      <span>
                        <b>Share</b>
                      </span>
                      <div className="-socials">
                        {socials.map(({ url, icon, label }, i) => (
                          <a
                            href={url}
                            key={i}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={label}
                          >
                            {icon}
                          </a>
                        ))}
                        {share.map(({ icon, label }, id) => (
                          <button
                            className={isActive ? "-active" : null}
                            variant="contained"
                            size="large"
                            onClick={() => {
                              CopyToClipboard(windowHref);
                              toggleClass();
                            }}
                            aria-label={label}
                          >
                            <span key={id}>{icon}</span>

                            <p>Copied to clipboard!</p>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ))}
    </Layout>
  );
};

export default ProjectDelays;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
